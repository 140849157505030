import * as React from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import SEO from "../components/SEO";

// styles
const pageStyles = {
  color: "#232129",
  paddingTop: "15rem",
  paddingBottom: "15rem",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

// markup
const NotFound: React.FC = () => {
  return (
    <>
      <SEO title="404: Pagina niet gevonden" />
      <Header />
      <section id="heading">
        <div className="row g-0 pb-5 pt-3">
          <div className="col width-wrapper">
            <ul>
              <li>
                <div style={pageStyles}>
                  <title>404: Pagina niet gevonden</title>
                  <h1 style={headingStyles}><b>404: </b>Deze pagina bestaat niet (meer). Onze excuses voor het ongemak.</h1>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <Footer />

    </>
  )
}

export default NotFound;
